import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { TestCloudFunction } from "../../components/TestCloudFunctions";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "your-first-cloud-function"
    }}>{`Your first cloud function`}</h1>
    <p>{`The core feature of serverless you'll use are cloud functions. They make things happen. We'll take a look at 3 providers and how they make cloud functions working.`}</p>
    <p>{`Starting with the good old hello world 😊`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media3.giphy.com/media/lcs5BL0NIM4WMv61a9/giphy-loop.mp4?cid=4ac046a2x26a883m87tdme9k17gfc2gicqyjap0rv017tr3d&rid=giphy-loop.mp4",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <h2 {...{
      "id": "vercel"
    }}>{`Vercel`}</h2>
    <p>{`Vercel uses an `}<a parentName="p" {...{
        "href": "https://expressjs.com/"
      }}>{`ExpressJS`}</a>{`-like cloud functions and filename routing. Files in `}<inlineCode parentName="p">{`/api`}</inlineCode>{` turn into cloud functions at the same path as their name.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Create a new project with an `}<inlineCode parentName="p">{`/api`}</inlineCode>{` directory and add a JavaScript file. Export an async method (using `}<inlineCode parentName="p">{`module.exports = `}</inlineCode>{`) that accepts a `}<inlineCode parentName="p">{`req`}</inlineCode>{` and `}<inlineCode parentName="p">{`res`}</inlineCode>{` argument.`}</p>
    <p>{`Use the `}<inlineCode parentName="p">{`.status`}</inlineCode>{` and `}<inlineCode parentName="p">{`.send`}</inlineCode>{` methods on `}<inlineCode parentName="p">{`res`}</inlineCode>{` to return a string.`}</p>
    <p>{`Deploy your project with the `}<inlineCode parentName="p">{`vercel`}</inlineCode>{` command. Use default answers to prompts.`}</p>
    <TestCloudFunction serviceName="vercel" urlPlaceholder="<domain>/api/<filename>" mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <h2 {...{
      "id": "netlify"
    }}>{`Netlify`}</h2>
    <p>{`Netlify's cloud functions behave as a thin wrapper on AWS Lambda. You get a more universal environment and codebase at the cost of more configuration work.`}</p>
    <p>{`Unlike Vercel, Netlify supports certain `}<a parentName="p" {...{
        "href": "https://docs.netlify.com/functions/trigger-on-events/"
      }}>{`event-driven functions`}</a>{`.`}</p>
    <h3 {...{
      "id": "exercise-1"
    }}>{`Exercise`}</h3>
    <p>{`Create a new project with an `}<inlineCode parentName="p">{`/functions`}</inlineCode>{` directory and add a JavaScript file. Export an async handler method (using `}<inlineCode parentName="p">{`exports.handler = `}</inlineCode>{`) that accepts an `}<inlineCode parentName="p">{`event`}</inlineCode>{` argument.`}</p>
    <p>{`Return an object with a `}<inlineCode parentName="p">{`statusCode`}</inlineCode>{` and a `}<inlineCode parentName="p">{`body`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    statusCode: 200,
    body: "Hello world"
}
`}</code></pre>
    <p>{`Add a `}<inlineCode parentName="p">{`netlify.toml`}</inlineCode>{` config file at the root:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-toml"
      }}>{`[build]
    functions = "functions"
`}</code></pre>
    <p>{`Deploy with `}<inlineCode parentName="p">{`netlify deploy`}</inlineCode>{`, set up as a new project.`}</p>
    <TestCloudFunction serviceName="netlify" urlPlaceholder="<domain>/.netlify/functions/<filename>" mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution-1"
    }}>{`Solution`}</h3>
    <h2 {...{
      "id": "aws-lambda"
    }}>{`AWS Lambda`}</h2>
    <p>{`AWS Lambda gives you access to the full power of serverless and is also the most cumbersome to use. You `}<em parentName="p">{`can`}</em>{` use their UI but I don't recommend it.`}</p>
    <p>{`Using a code-as-infra approach gets you close to the Netlify and Vercel developer experience. You can use AWS's official `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/cloudformation/"
      }}>{`CloudFormation`}</a>{` tooling, but I find `}<a parentName="p" {...{
        "href": "https://www.serverless.com/"
      }}>{`Serverless Framework`}</a>{` a more welcoming approach.`}</p>
    <p>{`Serverless Framework is open source tooling working on top of CloudFormation. Makes common operations easier and lets you drop down to CloudFormation when you need more power.`}</p>
    <h3 {...{
      "id": "exercise-2"
    }}>{`Exercise`}</h3>
    <p>{`Clone the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises"
      }}>{`serverless-workshop-exercises GitHub repository`}</a>{` and start with `}<inlineCode parentName="p">{`exercise-1`}</inlineCode>{`. You get a repository configured for serverless and I've added build and deploy scripts.`}</p>
    <p>{`It's set up with TypeScript - helps cut down debugging time, you'll see.`}</p>
    <p>{`Create a `}<inlineCode parentName="p">{`src/hello.ts`}</inlineCode>{` file that exports a `}<inlineCode parentName="p">{`handler`}</inlineCode>{` function. Return a response object just like in the Netlify example.`}</p>
    <p>{`Deploy with `}<inlineCode parentName="p">{`yarn deploy`}</inlineCode>{` or `}<inlineCode parentName="p">{`npm run deploy`}</inlineCode>{`.`}</p>
    <p><em parentName="p">{`PS: if you're not well versed in TypeScript yet, don't worry. We won't use anything crazy at this workshop.`}</em></p>
    <TestCloudFunction serviceName="Serverless" urlPlaceholder="<domain>/hello" mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution-2"
    }}>{`Solution`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/tree/exercise-1-solution"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/tree/exercise-1-solution`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      